<template lang="pug">
.container
  .row.mb-4
    .col-4.d-flex.justify-content-center
      p.h6 Class Time : {{ classTime }}
    .col-4.d-flex.justify-content-center
      p.h6 Material Title : {{ material.title }}
    .col-4.d-flex.justify-content-center
      p.h6 Material Level : {{ material.level }}
  b-card(v-if="levelRange !== {}"
    v-for="(vip, vipIndex) in vips"
    :key="vip.id"
    header-tag="header"
    footer-tag="footer"
  )
    template(#header)
      .d-flex.justify-content-between.align-items-center
        p.m-0.h6
          | LV.{{ vip.user.level }}
          |  - {{ vip.user.englishName }}
          |  - {{ vipStatus(vip.user.vipStatus) }}
          |  - {{ vip.id }}

    template(v-for="(field, index) in fields")
      template(v-if="submitData[vipIndex]")
        responsive-input(:title="field")
          b-form-select(
            v-model="submitData[vipIndex][field]['level']"
            :options="levelOptions(vip.user.level)"
          )
      .row(v-if="platform === Platform.WUWOW")
        .col
          b-form-textarea(
            v-model="submitData[vipIndex][field]['suggestion']"
            :placeholder="placeholder"
            :rows="6"
          ).mt-1.mb-3
    template(v-if="isJrDemo(platform, classType)")
      responsive-input(:title="'Overall Level'")
        b-form-select(
          v-model="submitData[vipIndex].averageLevel"
          :options="levelOptions(vip.user.level)"
        )
    .row(v-if="platform === Platform.WUWOW_JUNIOR")
      .col
        b-form-textarea(
          v-model="submitData[vipIndex]['suggestion']"
          :placeholder="placeholder"
          :rows="6"
        ).mt-1.mb-3

    //- 2023-09-08暫時關閉顧問推薦升級機制
    //- template(v-if="vip.canRecommendUpgrade")
    //-   responsive-input(title="Recommend Upgrade ( Allowed to recommend only after teaching 400 classes ) : ")
    //-     span.mr-3
    //-       input#yes(type="radio" :value="true" v-model="submitData[vipIndex]['upgradeLevel']")
    //-       label.m-0(for="yes") Yes
    //-     span
    //-       input#no(type="radio" :value="false" v-model="submitData[vipIndex]['upgradeLevel']")
    //-       label.m-0(for="no") No
    //-   .row
    //-     .col
    //-       b-form-textarea(
    //-          v-model="submitData[vipIndex]['recommendUpgrade']"
    //-         :placeholder="placeholder"
    //-         :rows="6"
    //-       ).mt-1.mb-3
  .row
    .col.d-flex.justify-content-center.align-items-center
      b-button.mr-3(@click="goBack" variant="secondary") Back
      b-button(@click="submit" variant="primary") Submit
</template>

<script>
import responsiveInput from '@/components/responsiveColumn/index.vue';
import { Fragment as VTemplate } from 'vue-fragment';
import { alertFailedMessage, alertSuccessMessage } from '@/utils/sweetAlert';
// import vipConstants from '@/constants/vip';

import api, {
  Platform,
} from '@lioshutan/api-package';
import lioshutanApi from '@/api/lib/lioshutanApi';
import wuwowLoadingMixin from '@/components/wuwowLoading/mixin/index.vue';

import cannedResponses from './consultantCannedResponses.json';
export default {
  components: {
    responsiveInput,
    VTemplate,
  },
  mixins: [wuwowLoadingMixin],
  data: () => ({
    Platform,
    platform: Platform.WUWOW,
    token: '',
    classroomId: '',
    classTime: '',
    classType: 0,
    gradable: false,
    submitData: [],
    material: {
      id: 0,
      interestedTopics: [],
      level: 0,
      link: '',
      title: '',
    },

    // type: 'business',
    type: 'themeAndTravel',

    cannedResponses,

    vips: [],
    placeholder: 'Suggestions for improvement.',
    fields: [
      'vocabulary',
      'listening',
      'grammar',
      'speaking',
      'reading',
      'pronunciation',
    ],
    evaluation: {
      vocabulary: {
        value: null,
        content: null,
      },
      listening: {
        value: null,
        content: null,
      },
      grammar: {
        value: null,
        content: null,
      },
      speaking: {
        value: null,
        content: null,
      },
      reading: {
        value: null,
        content: null,
      },
      pronunciation: {
        value: null,
        content: null,
      },
    },
    levelRange: {
    },
  }),
  async created() {
    this.token = this.$store.state.auth.token;
    this.classroomId = this.$route.params.classroomId;
    await this.setDefaultData();
  },
  methods: {
    async setDefaultData() {
      const tag = this.startLoading();
      try {
        const response = await api
          .setDNS(process.env.VUE_APP_BASE_API)
          .analysis(this.token)
          .getClassroomAllStudentInformation({
            classroomId: this.classroomId,
          });
        this.platform = response.data.classRecord.platform;
        this.classType = response.data.classRecord.classType;
        this.vips = response.data.classRecord.vips;
        this.material = response.data.classRecord.material;
        this.gradable = response.data.classRecord.hbConsultant.gradeable;
        const processTime = response.data.classRecord.title.split('_')[0];
        const prefixTime = processTime.slice(0, processTime.length - 2);
        const suffixTime = processTime.slice(processTime.length - 2);
        this.classTime = `${prefixTime}:${suffixTime}:00`;

        this.submitData = response.data.classRecord.vips.map(vip => ({
          classApplicationId: vip.id,
          vocabulary: {
            level: vip.consultantVipAnalysis.vocabularyLevel,
            suggestion: vip.consultantVipAnalysis.suggestionOfVocabulary,
          },
          listening: {
            level: vip.consultantVipAnalysis.listeningLevel,
            suggestion: vip.consultantVipAnalysis.suggestionOfListening,
          },
          grammar: {
            level: vip.consultantVipAnalysis.grammarLevel,
            suggestion: vip.consultantVipAnalysis.suggestionOfGrammar,
          },
          speaking: {
            level: vip.consultantVipAnalysis.speakingLevel,
            suggestion: vip.consultantVipAnalysis.suggestionOfSpeaking,
          },
          reading: {
            level: vip.consultantVipAnalysis.readingLevel,
            suggestion: vip.consultantVipAnalysis.suggestionOfReading,
          },
          pronunciation: {
            level: vip.consultantVipAnalysis.pronunciationLevel,
            suggestion: vip.consultantVipAnalysis.suggestionOfPronunciation,
          },
          averageLevel: vip.user.level,
          recommendUpgrade: vip.consultantVipAnalysis.recommendUpgrade,
          upgradeLevel: this.isJrDemo(this.platform, this.classType),
          suggestion: vip.consultantVipAnalysis.suggestionOfConsultant,
        }));

        // 判斷目前平台取得對應等級
        if (this.platform === 1){
          await this.getPlatformId('wuwow');
        }
        if (this.platform === 2){
          await this.getPlatformId('wuwow_junior');
        }

        this.endLoading(tag);
      } catch (error) {
        this.endLoading(tag);
        await alertFailedMessage('Something went wrong! Please contact customer service!');
        this.goBack();
      }
    },
    // 取得platformId
    async getPlatformId(platform) {
      try {
        const response = await lioshutanApi
          .classroom
          .getPlatformId(platform);
        const { id } = response.data.data.platform;
        await this.getPlatformLevel(id);
      } catch (error) {
        await alertFailedMessage('Please contact support:<br>65b40a8f-6880-4688-ba7d-004b086caad0');
      }
    },

    // 取得平台 最小最大等級
    async getPlatformLevel(id) {
      try {
        const response = await lioshutanApi
          .classroom
          .getPlatformLevel(id);
        const { levelRange } = response.data.data;
        this.levelRange = levelRange;
      } catch (error) {
        await alertFailedMessage('Please contact support:<br>65b40a8f-6880-4688-ba7d-004b086caad0');
      }
    },
    vipStatus(status) {
      let result = '';
      switch (status) {
        case 0:
          result = 'No Show';
          break;
        case 1:
          result = 'Show';
          break;
        case 2:
          result = 'Late';
          break;
        case 3:
          result = 'Tardy';
          break;
        case 4:
          result = 'Problem';
          break;
        default:
          throw new Error('不存在的狀態');
      }
      return result;
    },

    levelOptions(level) {
      // 最低最高等級改用API
      const minIndex = this.levelRange.minLevel;
      const maxIndex = this.levelRange.maxLevel;

      const canShowLevel = [];

      // Demo
      if (this.isJrDemo(this.platform, this.classType)){
        // 範圍從 minIndex 到 maxIndex
        for (let i = minIndex; i <= maxIndex; i++) {
          canShowLevel.push(i);
        }
      } else { // 一般課程
        // 根據當前等級與最低和最高等級的對比，確定可以顯示的等級
        switch (true) {
          case level < minIndex:
            // 如果當前等級低於最低等級，只能選擇最低等級
            canShowLevel.push(minIndex);
            break;

          case level > maxIndex:
            // 如果當前等級高於最高等級，只能選擇最高等級
            canShowLevel.push(maxIndex);
            break;

          default:
            // 當前等級在最低和最高等級之間
            if (level > minIndex) {
              canShowLevel.push(level - 1);
            }
            canShowLevel.push(level);
            if (level < maxIndex) {
              canShowLevel.push(level + 1);
            }
            break;
        }
      }

      const options = canShowLevel.map((index) => ({
        value: index,
        text: `Level. ${index}`,
        disabled: !canShowLevel.includes(index),
      }));
      return options;
    },

    goBack() {
      this.$router.push({ path: '/console/consultant/classRecords' });
    },

    async submit() {
      const loading = this.$loading.show();
      const allAnalysisPromises = this.submitData.map(data => api
        .setDNS(process.env.VUE_APP_BASE_API)
        .analysis(this.token)
        .consultantAddStudentAnalysis({
          classApplicationId: data.classApplicationId,

          vocabularyLevel: data.vocabulary.level,
          listeningLevel: data.listening.level,
          grammarLevel: data.grammar.level,
          speakingLevel: data.speaking.level,
          readingLevel: data.reading.level,
          pronunciationLevel: data.pronunciation.level,

          suggestionOfVocabulary: data.vocabulary.suggestion || '-',
          suggestionOfListening: data.listening.suggestion || '-',
          suggestionOfGrammar: data.grammar.suggestion || '-',
          suggestionOfSpeaking: data.speaking.suggestion || '-',
          suggestionOfReading: data.reading.suggestion || '-',
          suggestionOfPronunciation: data.pronunciation.suggestion || '-',

          averageLevel: data.averageLevel,
          recommendUpgrade: data.recommendUpgrade,
          upgradeLevel: data.upgradeLevel,
          suggestionOfConsultant: data.suggestion,
          platform: this.platform,
        }));
      try {
        // TODO: 後續要加入Appier的相關動作
        const response = await Promise.all(allAnalysisPromises);
        loading.hide();
        if (response[0].data.status === true) {
          await alertSuccessMessage('Successfully');
          this.goBack();
        }
      } catch (error) {
        loading.hide();
        await alertFailedMessage('Please fill in all fields and options !!');
      }
    },

    isJrDemo(platform, classType) {
      const jrDemoclassType = [3, 16];
      return (platform === Platform.WUWOW_JUNIOR) && jrDemoclassType.includes(classType);
    },
  },
};
</script>
