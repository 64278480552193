var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-4 d-flex justify-content-center"
  }, [_c('p', {
    staticClass: "h6"
  }, [_vm._v("Class Time : " + _vm._s(_vm.classTime))])]), _c('div', {
    staticClass: "col-4 d-flex justify-content-center"
  }, [_c('p', {
    staticClass: "h6"
  }, [_vm._v("Material Title : " + _vm._s(_vm.material.title))])]), _c('div', {
    staticClass: "col-4 d-flex justify-content-center"
  }, [_c('p', {
    staticClass: "h6"
  }, [_vm._v("Material Level : " + _vm._s(_vm.material.level))])])]), _vm._l(_vm.vips, function (vip, vipIndex) {
    return _vm.levelRange !== {} ? _c('b-card', {
      key: vip.id,
      attrs: {
        "header-tag": "header",
        "footer-tag": "footer"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [_c('div', {
            staticClass: "d-flex justify-content-between align-items-center"
          }, [_c('p', {
            staticClass: "m-0 h6"
          }, [_vm._v("LV." + _vm._s(vip.user.level) + " - " + _vm._s(vip.user.englishName) + " - " + _vm._s(_vm.vipStatus(vip.user.vipStatus)) + " - " + _vm._s(vip.id))])])];
        },
        proxy: true
      }], null, true)
    }, [_vm._l(_vm.fields, function (field, index) {
      return [_vm.submitData[vipIndex] ? [_c('responsive-input', {
        attrs: {
          "title": field
        }
      }, [_c('b-form-select', {
        attrs: {
          "options": _vm.levelOptions(vip.user.level)
        },
        model: {
          value: _vm.submitData[vipIndex][field]['level'],
          callback: function ($$v) {
            _vm.$set(_vm.submitData[vipIndex][field], 'level', $$v);
          },
          expression: "submitData[vipIndex][field]['level']"
        }
      })], 1)] : _vm._e(), _vm.platform === _vm.Platform.WUWOW ? _c('div', {
        staticClass: "row"
      }, [_c('div', {
        staticClass: "col"
      }, [_c('b-form-textarea', {
        staticClass: "mt-1 mb-3",
        attrs: {
          "placeholder": _vm.placeholder,
          "rows": 6
        },
        model: {
          value: _vm.submitData[vipIndex][field]['suggestion'],
          callback: function ($$v) {
            _vm.$set(_vm.submitData[vipIndex][field], 'suggestion', $$v);
          },
          expression: "submitData[vipIndex][field]['suggestion']"
        }
      })], 1)]) : _vm._e()];
    }), _vm.isJrDemo(_vm.platform, _vm.classType) ? [_c('responsive-input', {
      attrs: {
        "title": 'Overall Level'
      }
    }, [_c('b-form-select', {
      attrs: {
        "options": _vm.levelOptions(vip.user.level)
      },
      model: {
        value: _vm.submitData[vipIndex].averageLevel,
        callback: function ($$v) {
          _vm.$set(_vm.submitData[vipIndex], "averageLevel", $$v);
        },
        expression: "submitData[vipIndex].averageLevel"
      }
    })], 1)] : _vm._e(), _vm.platform === _vm.Platform.WUWOW_JUNIOR ? _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_c('b-form-textarea', {
      staticClass: "mt-1 mb-3",
      attrs: {
        "placeholder": _vm.placeholder,
        "rows": 6
      },
      model: {
        value: _vm.submitData[vipIndex]['suggestion'],
        callback: function ($$v) {
          _vm.$set(_vm.submitData[vipIndex], 'suggestion', $$v);
        },
        expression: "submitData[vipIndex]['suggestion']"
      }
    })], 1)]) : _vm._e()], 2) : _vm._e();
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col d-flex justify-content-center align-items-center"
  }, [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v("Back")]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("Submit")])], 1)])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }